import { createTheme, ThemeProvider } from "@mui/material/styles";
import LandingPage from "./LandingPage";
import CssBaseline from "@mui/material/CssBaseline";

const darkTheme = createTheme({
  palette: {
    primary: {
      main: "#2196f3",
    },
    secondary: {
      main: "#f50057",
    },
    mode: "dark",
  },
});

export const App = () => {
  const theme = darkTheme;
  return (
    <>
      <CssBaseline />
      <ThemeProvider theme={theme}>
        <LandingPage />
      </ThemeProvider>
    </>
  );
};

export default App;
