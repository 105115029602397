import React from "react";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import App from "./App";

import { logEvent } from "./analytics";
import { app } from "./firebase";
import { getAuth, signInAnonymously } from "firebase/auth";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const auth = getAuth(app);
signInAnonymously(auth)
  .then(() => {
    root.render(
      <React.StrictMode>
        <App />
      </React.StrictMode>
    );
  })
  .catch((error) => {
    const errorCode = error.code;
    const errorMessage = error.message;
    console.log({ errorCode, errorMessage });
    logEvent("error_signing_in_anonymously", { errorCode, errorMessage });
    root.render(
      <React.StrictMode>
        <h1>An Error has occured, please try again later</h1>
      </React.StrictMode>
    );
  });

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

logEvent("page_view");
