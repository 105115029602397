import React from "react";
import { makeStyles } from "tss-react/mui";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import {
  Box,
  Button,
  Link,
  TextField,
  Theme,
  Typography,
  useTheme,
} from "@mui/material";
import { logEvent } from "./analytics";
import { app } from "./firebase";
import {
  getFirestore,
  collection,
  addDoc,
  getDocs,
  query,
  where,
} from "firebase/firestore";
import { Formik, Form } from "formik";
import * as yup from "yup";
import { FormikHelpers } from "formik/dist/types";

const useStyles = makeStyles<void>()((theme: Theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    height: "100vh",
    // background: 'url("assets/background.jpeg")',
    // backgroundSize: "cover",
    // backgroundColor: "#f9dbd5",
    // filter: theme.palette.mode === "dark" ? "invert(1)" : "none",
    width: "100%",
    background: [
      "black",
      "radial-gradient(ellipse at 100% -10%, rgba(0, 240, 255, 1) 0%, rgba(21, 20, 91, 1) 20%, rgba(2, 2, 11, 1) 30%, rgba(2, 2, 11, 1) 67%, rgba(41, 21, 78, 0) 80%)",
    ],

    "&:after": {
      content: '""',
      position: "absolute",
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
      width: "100%",
      height: "100%",
      background:
        "radial-gradient(ellipse at -0% 105%, rgba(238, 130, 238, 1) 0%, rgba(41, 21, 78, 1) 20%, rgba(2, 2, 11, 1) 33%, rgba(2, 2, 11, 1) 70%, rgba(21, 20, 91, 0) 80%)",
    },
  },
  contents: {
    padding: "40px 40px 40px 40px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
    height: "100%",
    color: "white",
    zIndex: 1,
  },
  header: {
    textAlign: "center",
    marginBottom: theme.spacing(4),
    fontSize: "140px",
    fontWeight: 800,
    mixBlendMode: "hard-light",
    color: "white",
  },
  subheader: {
    textAlign: "center",
    fontWeight: "bold",
    marginBottom: theme.spacing(2),
    color: "white",
  },
  form: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
  },
  input: {
    marginBottom: theme.spacing(2),
    width: "50vw",
    minWidth: "300px",
    flexGrow: 1,
  },
  button: {
    padding: "1rem",
    background:
      "linear-gradient(45deg, rgba(238, 130, 238, 1) 0%, rgba(0, 240, 255, 1) 100%)",
    color: "black",
    fontWeight: "bold",
  },
  footer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    width: "6rem",
  },
}));

interface FormValues {
  email: string;
}

const validationSchema = yup.object().shape({
  email: yup
    .string()
    .email("Invalid Email")
    // Apparently dotless emails are a valid thing for niche use cases, but we don't want to accept those
    .matches(/@.+\..+/, { message: "Invalid Email" })
    .required("Required"),
});

const LandingPage: React.FC = () => {
  const { classes, cx } = useStyles();
  const theme = useTheme();
  const [emailAdded, setEmailAdded] = React.useState(false);

  const db = React.useMemo(() => getFirestore(app), []);

  const onEmailSubmit = React.useCallback(
    async (
      { email }: FormValues,
      { setSubmitting }: FormikHelpers<FormValues>
    ) => {
      try {
        const ref = collection(db, "beta_emails");

        const q = await query(ref, where("email", "==", email));
        const querySnapshot = await getDocs(q);

        if (querySnapshot.docs.length > 0) {
          logEvent("already_added_email");
          setEmailAdded(true);
          setSubmitting(false);
          return;
        }
        const docRef = await addDoc(ref, {
          email,
        });
        console.log("Document written with ID: ", docRef.id);
        logEvent("added_email");
        setEmailAdded(true);
        setSubmitting(false);
      } catch (e: unknown) {
        console.error("Error adding email doc: ", e);
        logEvent("error_adding_email", { error: (e as Error).toString() });
      }
    },
    [db, setEmailAdded]
  );

  return (
    <div className={cx(classes.root)}>
      <div className={cx(classes.contents)}>
        <Typography variant="h3" className={classes.header}>
          KickIt List
        </Typography>
        <Typography variant="h4" className={classes.subheader}>
          Let's turn your 'want to' into 'done'!
        </Typography>
        {emailAdded ? (
          <Box>
            <Typography variant="h6" className={classes.subheader}>
              Thanks for joining the waitlist!
            </Typography>
          </Box>
        ) : (
          <Box>
            <Formik
              initialValues={{ email: "" }}
              onSubmit={onEmailSubmit}
              validationSchema={validationSchema}
            >
              {formikProps => (
                <Form>
                  <TextField
                    fullWidth={true}
                    id="email"
                    name="email"
                    label="Email"
                    type="email"
                    value={emailAdded ? "" : formikProps.values.email}
                    onChange={formikProps.handleChange}
                    onBlur={formikProps.handleBlur}
                    variant="outlined"
                    disabled={emailAdded}
                    className={cx(classes.input)}
                    error={
                      formikProps.touched.email &&
                      Boolean(formikProps.errors.email)
                    }
                    helperText={
                      (formikProps.touched.email && formikProps.errors.email) ??
                      "Join our exclusive waitlist to be the first to know when we launch!"
                    }
                  />
                  <Button
                    type="submit"
                    variant="contained"
                    className={cx(classes.button)}
                    disabled={emailAdded || formikProps.isSubmitting}
                  >
                    Join Waitlist
                  </Button>
                </Form>
              )}
            </Formik>
          </Box>
        )}
        <div className={cx(classes.footer)}>
          <Link
            href="https://twitter.com/kickitlist"
            color={theme.palette.text.primary}
            target="_blank"
            rel="noopener"
          >
            <TwitterIcon />
          </Link>
          <Link
            href="https://instagram.com/kickitlist"
            color={theme.palette.text.primary}
            target="_blank"
            rel="noopener"
          >
            <InstagramIcon />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default LandingPage;
