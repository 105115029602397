// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
export const firebaseConfig = {
  apiKey: "AIzaSyCkX81dT0X4q7txlwTa8pg94ygVkf_jjts",
  authDomain: "kickitlist-9f82c.firebaseapp.com",
  projectId: "kickitlist-9f82c",
  storageBucket: "kickitlist-9f82c.appspot.com",
  messagingSenderId: "726444231830",
  appId: "1:726444231830:web:b6963d712c99b677aa46b7",
  measurementId: "G-KG6ECH6G7C",
};